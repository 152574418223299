import s from "styled-components";

import {Section} from "../shared";

interface ExampleSite {
  name: string;
  photo: string;
  company: string;
  title: string;
  works: {name: string; icon: string}[];
  hiddenInMobile: boolean;
}

const EXAMPLES: ExampleSite[] = [
  {
    name: "Dr. Lilian Lam",
    photo: "lillian",
    company: "Federation Bio",
    title: "Immuno-oncology Scientist",
    works: [
      {name: "Stanford University School of Medicine", icon: "stanford"},
      {name: "University of Oxford", icon: "oxford"},
      {name: "Oxford Centre for Microbiome Studies", icon: "ocms"},
      {name: "Swarthmore College", icon: "swarthmore"},
    ],
    hiddenInMobile: true,
  },
  {
    name: "Mason Smith",
    photo: "mason",
    company: "TGS Management",
    title: "Head of Software Engineering",
    works: [
      {name: "Caltech Board of Trustees", icon: "caltech"},
      {name: "TGS Management Company", icon: "tgs"},
      {name: "University of California, Berkeley", icon: "berkeley"},
      {name: "California Institute of Technology", icon: "caltech"},
    ],
    hiddenInMobile: false,
  },
  {
    name: "Suneal Bedi, JD, PhD",
    photo: "suneal",
    company: "Indiana University",
    title: "Assistant Professor of Business Law & Ethics",
    works: [
      {name: "Indiana University", icon: "indiana-university"},
      {name: "Harvard Law School", icon: "hls"},
      {name: "The Wharton School, Univ. of Pennsylvania", icon: "wharton"},
      {name: "Swarthmore College", icon: "swarthmore"},
    ],
    hiddenInMobile: true,
  },
  {
    name: "Jasmine Rodriguez",
    photo: "jasmine",
    company: "Writer and Journalist",
    title: "Flaunt Magazine",
    works: [
      {name: "Flaunt Magazine", icon: "flaunt"},
      {name: "U.S. House of Representatives", icon: "hor"},
      {name: "UCLA", icon: "ucla"},
      {name: "UC Santa Barbara", icon: "ucsb"},
    ],
    hiddenInMobile: false,
  },

  {
    name: "Andres Pacheco",
    photo: "andres",
    company: "Intersect Power",
    title: "Head of Strategy and Business Development",
    works: [
      {name: "Intersect Power", icon: "intersect"},
      {name: "Macquarie Group", icon: "macquarie"},
      {name: "Tesla", icon: "tesla"},
      {name: "Recurrent Energy", icon: "recurrent"},
    ],
    hiddenInMobile: true,
  },
  {
    name: "Sonu Bedi, JD, PhD",
    photo: "sonu",
    company: "Darthmouth College",
    title: "Professor in Law and Political Science",
    works: [
      {name: "Dartmouth College", icon: "dartmouth"},
      {name: "Yale University", icon: "yale"},
      {name: "Harvard Law School", icon: "hls"},
      {name: "Brown University", icon: "brown"},
    ],
    hiddenInMobile: false,
  },
];

export default function Sites() {
  return (
    <Section
      title="Websites Powered by Publit"
      titleColor="var(--color-navy)"
      description="Whether you're starting your career or managing an entire division, Publit allows you to showcase everything great you've accomplished."
      descriptionMaxWidth="500px"
      background="white"
    >
      <Grid>
        {EXAMPLES.map((site, i) => (
          <Site key={`site-${i}`} hiddenInMobile={site.hiddenInMobile}>
            <About>
              <Photo src={`/static/home/sites/${site.photo}.jpg`} />
              <Name>{site.name}</Name>
              <Company>{site.company}</Company>
              <Title>{site.title}</Title>
            </About>
            <Works>
              {site.works.map((work, wi) => (
                <WorkItem key={`site-${i}-work-${wi}`}>
                  <WorkIcon
                    src={`/static/home/sites/logos/${work.icon}.png`}
                    alt={work.name}
                  />
                  <ItemName>{work.name}</ItemName>
                </WorkItem>
              ))}
            </Works>
          </Site>
        ))}
      </Grid>
    </Section>
  );
}

const Grid = s.div`
display: grid;
grid-template-columns: 600px 600px;
justify-content: center;
grid-gap: 40px;
margin-top: 60px;

@media (max-width: 1300px) {
  grid-template-columns: 600px;
}

@media (max-width: 660px) {
  grid-gap: 30px;
  grid-template-columns: 1fr;
  margin: 60px 30px 0;
}
`;

const Site = s.div<{hiddenInMobile: boolean}>`
display: grid;
grid-template-columns: auto 380px;
align-items: stretch;

border: 0.5px solid #D6D6D6;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
border-radius: 20px;
overflow: hidden;
transition: 0.15s box-shadow ease-in-out;

${props => props.theme.shadows.party("hover")};

@media (max-width: 1300px) {
  display: ${props => (props.hiddenInMobile ? "none" : "grid")};
}

@media (max-width: 660px) {
  display: block;
}
`;

const About = s.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 30px 25px;
`;

const Photo = s.img`
display: block;
width: 80px;
height: 80px;

border-radius: 100%;
`;

const Name = s.h2`
margin: 16px 0;

color: black;
text-align: center;
${props => props.theme.text.build("manrope", "medium", "bold")};
`;

const Company = s.h3`
margin: 0;

color: var(--color-blue);
text-align: center;
${props => props.theme.text.build("manrope", "medium", "bold")};
`;

const Title = s.h4`
margin: 0;

color: black;
text-align: center;
${props => props.theme.text.build("manrope", "s")};
`;

const Works = s.div`
padding: 20px;
background: linear-gradient(90deg, rgba(0, 51, 97, 0.05) 0%, rgba(1, 106, 201, 0) 100%);
`;

const WorkItem = s.div`
display: flex;
align-items: center;
height: 48px;

border-radius: 10px;
background: white;
border: 0.5px solid #DBDBDB;
overflow: hidden;

&:not(:first-child) {
  margin-top: 8px;
}
`;

const WorkIcon = s.img`
display: block;
width: 48px;
height: 48px;
`;

const ItemName = s.p`
margin: 0;
padding: 0 2px;

color: black;
${props => props.theme.text.build("manrope", "s")};
`;
