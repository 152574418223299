import {RoundedButton} from "bits/Button";
import GenericHero from "../generic-hero";

import Slider_1 from "../../../../public/static/home/hero/slider-1.jpg";
import Slider_2 from "../../../../public/static/home/hero/slider-2.jpg";
import Slider_3 from "../../../../public/static/home/hero/slider-3.jpg";
import Slider_4 from "../../../../public/static/home/hero/slider-4.jpg";

export default function HomeHero() {
  return (
    <GenericHero
      callouts={["Effortlessly Create a", "Personal Website in Minutes"]}
      descriptions={[
        "Publit helps you make and manage your personal site. Join thousands of professionals and students who are sharing their experiences with a Publit-powered site.",
      ]}
      sliderImages={[Slider_1, Slider_2, Slider_3, Slider_4]}
      exampleURL="jasonheo.com"
      cta={
        <RoundedButton
          size="medium"
          title="Create Now"
          href="/signup?from=hero"
          highlight
        />
      }
    />
  );
}
