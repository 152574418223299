import {useMemo, useState} from "react";
import s from "styled-components";

import {ButtonAccessory, RoundedButton} from "bits/Button";
import {Section} from "../shared";

const SHOWCASES = [
  {
    id: "phd-candidate",
    name: "PhD Candidate",
    category: "Grad Students",
    items: [
      {text: "Researching Working Paper 1", icon: "google-drive"},
      {text: "Writings on Medium", icon: "medium"},
      {text: "PhD & Academic Life YouTube Channel", icon: "youtube"},
      {text: "Grad Project Diagrams", icon: "google-drive"},
      {text: "International Travel Photography Blog", icon: "instagram"},
      {text: "PDF Resume", icon: "pdf"},
    ],
  },
  {
    id: "undergrad",
    name: "Undergraduate",
    category: "Undergraduates",
    items: [
      {text: "Senior Thesis", icon: "google-drive"},
      {text: "Environmental Biology Term Project", icon: "youtube"},
      {text: "Health/Nutrition Blog", icon: "medium"},
      {text: "Popular LinkedIn Post", icon: "linkedin"},
      {text: "Viral TikTok Video", icon: "tiktok"},
      {text: "PDF Resume", icon: "pdf"},
    ],
  },
  {
    id: "professional",
    name: "Professional",
    category: "Professionals",
    items: [
      {text: "New York Times Feature", icon: "nyt"},
      {text: "Personal Finance Writing", icon: "medium"},
      {text: "Expert Panel Discussion", icon: "youtube"},
      {text: "Viral Expert Tweet", icon: "twitter"},
      {text: "Popular LinkedIn Post", icon: "linkedin"},
      {text: "Professional CV", icon: "pdf"},
    ],
  },
  {
    id: "academic",
    name: "Academic",
    category: "Academics",
    items: [
      {text: "New York Times Op-Ed", icon: "nyt"},
      {text: "Research and WIPs", icon: "publit"},
      {text: "Conference Presentation", icon: "google-drive"},
      {text: "100,000-Impression Tweet", icon: "twitter"},
      {text: "NBER Working Papers", icon: "nber"},
      {text: "Academic CV", icon: "pdf"},
    ],
  },
  {
    id: "writer",
    name: "Writer",
    category: "Writers",
    items: [
      {text: "Fiction Essays", icon: "medium"},
      {text: "American Fiction Blog", icon: "publit"},
      {text: "Sample Chapters of Book Proposal", icon: "google-drive"},
      {text: "Videos about Writing", icon: "youtube"},
      {text: "Podcast Show on Fiction Writing", icon: "podcast"},
      {text: "Writing Resume", icon: "pdf"},
    ],
  },
  {
    id: "applicant",
    name: "Fellowship Applicant",
    category: "Applicants",
    items: [
      {text: "Student Body President Election", icon: "swarthmore"},
      {text: "White House Internship Selection", icon: "wh"},
      {text: "National Short Story Competition Winner", icon: "medium"},
      {text: "Economic Trends and Research Blog", icon: "publit"},
      {text: "iOS Mobile App Launch", icon: "ios-app"},
      {text: "Resume", icon: "pdf"},
    ],
  },
];

export default function Showcase() {
  const [index, setIndex] = useState(0);

  const person = useMemo(() => SHOWCASES[index], [index]);

  return (
    <Section
      title="Showcase your best experiences in a website"
      titleColor="var(--color-navy)"
      description="Whether you're starting your career or managing an entire division, Publit allows you to showcase all of your accomplishments."
      descriptionMaxWidth="500px"
      background="linear-gradient(180deg, #EAFAFF 0%, #FBFEFF 100%)"
    >
      <Box>
        <Columns>
          <Personas>
            {SHOWCASES.map((item, idx) => (
              <Person
                key={`showcase-person-${idx}`}
                active={index === idx}
                onMouseOver={() => setIndex(idx)}
              >
                <Dot active={index === idx} />
                <span>{item.category}</span>
              </Person>
            ))}
          </Personas>
          <Human>
            <HeadshotContainer>
              <Headshot
                src={`/static/home/showcase/personas/${person.id}.png`}
              />
            </HeadshotContainer>
            <Name>{person.name}</Name>
          </Human>
          <Content>
            {person.items.map((item, idx) => (
              <ContentItem key={`showcase-${person.id}-${idx}`}>
                <ItemIcon
                  src={`/static/home/showcase/icons/${item.icon}.png`}
                />
                <ContentText>{item.text}</ContentText>
              </ContentItem>
            ))}
          </Content>
        </Columns>
        <ButtonContainer>
          <RoundedButton
            title="Create a Site Now"
            size="medium"
            href="/signup?from=hero"
            width="260px"
            accessory={ButtonAccessory.Arrow}
          />
        </ButtonContainer>
      </Box>
    </Section>
  );
}

const Box = s.div`
max-width: 780px;
margin: 0 auto;
margin-top: 60px;
padding: 40px;
box-sizing: border-box;

background: white;
border: 0.5px solid #D6D6D6;
border-radius: 20px;

@media (max-width: 800px) {
  max-width: auto;
  margin-left: 10px;
  margin-right: 10px;
}
`;

const Columns = s.div`
display: grid;
grid-template-columns: repeat(3, 1fr);

@media (max-width: 680px) {
  display: block;
}
`;

const Personas = s.div`
@media (max-width: 680px) {
  display: flex;
  overflow: scroll;

  margin-top: -10px;
  margin-bottom: 20px;
}
`;

const Person = s.button<{active: boolean}>`
position: relative;

display: flex;
align-items: center;
padding: 20px 0;
margin: 0;

background: none;
border: none;
color: ${props => (props.active ? "var(--color-blue)" : "#CCCCCC")};
cursor: pointer;
transition: color 0.15s ease-in-out;
${props => props.theme.text.build("manrope", "large", "bold")};

@media (min-width: 680px) {
  &:first-child {
    padding-top: 0;

    &::before {
      display: none;
    }
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }

  &::before, &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 28px;
    transition: background 0.15s ease-in-out;
  }

  &::before {
    left: 4.5px; top: 0;

    background: linear-gradient(to top, ${props =>
      props.active ? "var(--color-blue)" : "#dfdfdf"}, #dfdfdf);
  }

  &::after {
    left: 4.5px; bottom: 0;

    background: linear-gradient(to bottom, ${props =>
      props.active ? "var(--color-blue)" : "#dfdfdf"}, #dfdfdf);
  }
}

@media (max-width: 680px) {
  margin: 0 5px;
  white-space: nowrap;
  ${props => props.theme.text.build("manrope", "medium", "bold")};
}
`;

const Dot = s.span<{active: boolean}>`
position: relative;
z-index: 2;

display: block;
width: 10px;
height: 10px;
margin-right: 32px;

border-radius: 100%;
background: currentColor;
box-shadow: ${props =>
  props.active
    ? "inset -1px -1px 2px rgba(0, 0, 0, 0.25), 0 0 0 5px rgba(var(--color-blue-raw), 0.2)"
    : "inset -1px -1px 2px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(var(--color-blue-raw), 0)"};
transition: background 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

@media (max-width: 680px) {
  display: none;
}
`;

const Human = s.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const HeadshotContainer = s.div`
position: relative;
z-index: 1;

width: 148px;
height: 148px;

&::before {
  content: "";

  position: absolute;
  z-index: -1;
  top: -10px; bottom: -10px; left: -10px; right: -10px;

  border-radius: 100%;
  border: 0.5px solid #DBDBDB;
}

@media (max-width: 680px) {
  width: 100px;
  height: 100px;
}
`;

const Headshot = s.img`
display: block;
width: 100%;
border-radius: 100%;
`;

const Name = s.p`
margin: 30px 10px;

${props => props.theme.text.build("manrope", "medium", "bold")}
color: black;
text-align: center;

@media (max-width: 680px) {
  margin: 20px 0;
}
`;

const Content = s.div``;

const ContentItem = s.div`
position: relative;

display: flex;
align-items: center;
box-sizing: border-box;
padding: 10px;
width: 240px;
height: 60px;

transition: margin 0.15s ease-in-out;

cursor: default;
background: white;
border: 0.5px solid #DBDBDB;
border-radius: 10px;
overflow: hidden;

&:not(:first-child) {
  margin-top: 2px;
}

@media (max-width: 800px) {
  width: 220px;
}

@media (max-width: 680px) {
  width: 100%;
}
`;

const ItemIcon = s.img`
display: block;
width: 50px;
`;

const ContentText = s.p`
margin: 0;
${props => props.theme.text.build("manrope", "medium")};
color: black;
`;

const ButtonContainer = s.div`
display: flex;
justify-content: center;
margin-top: 30px;
`;
